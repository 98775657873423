














import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DxFullDataTable: () => import("@/components/DataTableGenerico/DxFullDataTable.vue"),
  },
})
export default class logs_formulario extends Vue {




}
